<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>MY 서비스</li>
							<li>초대하기 관리</li>
						</ul>
					</div>
					<h2 class="page_title">초대하기 관리</h2>
				</div>
			</div>

			<div class="content_body">
				<div class="inner">
					<div class="tbl_input">
						<table>
							<tr v-if="idx==undefined">
								<th>이름 <span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="invite.guestName" placeholder="이름을 입력하세요.">
									</div>
								</td>
							</tr>
							<!-- idx 없을 때 -->
							<tr v-else>
								<th>이름 <span class="require">*</span></th>
								<td>
									<div class="text_box">
										{{ invite?.guestName }}
									</div>
								</td>
							</tr>
							<tr>
								<th>E-mail<span class="require">*</span></th>
								<td>
									<div class="inp_box i1">
										<input type="text" class="inp" v-model="invite.guest" placeholder="구글 Email을 입력해주세요.">
									</div>
								</td>
							</tr>
						</table>
					</div>

					<div class="button_area">
						<button type="button" class="btn_cancle btn_black" @click="goBack">취소</button>
						<button v-if="idx!=undefined" type="button" class="btn_delete btn_black" @click="deleteOne">삭제하기</button>
						<button type="submit" class="btn_submit btn_primary" @click="saveInviteOne">등록하기</button>
					</div>

				</div>
			</div>

		</div>
	</main>
</template>

<script>
export default {
	name: "inviteView",
	data() {
		return {
			idx: this.$route.query.idx,
			invite: {}
		}
	},
	mounted() {
		if (this.idx != undefined) {
			this.getMyInviteOne()
		}
	},
	methods: {

		deleteOne() {
			this.axiosCaller.post(this, this.APIs.MY_SERVICE + "/deleteMyInviteOne", { 'idx' : this.idx}, (res) => {
				var result = res.data
				if (result.status === "ok") {

					this.swalUtils.gritter("삭제되었습니다.", result.msg, "success", 800).then(() => {
						location.href="/myService/inviteList"
					});

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})
		},

		goBack() {
			history.back()
		},

		saveInviteOne() {

			this.invite.inviter = this.getSessionProc().emailId;

			this.axiosCaller.post(this, this.APIs.MY_SERVICE + "/saveMyInviteOne", this.invite, (res) => {
				var result = res.data
				if (result.status === "ok") {

					this.swalUtils.gritter("저장되었습니다.", result.msg, "success", 800).then(() => {
						location.href="/myService/inviteList"
					});

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})
		},

		getMyInviteOne() {
			const self = this;

			let temp = {}
			this.$eventBus.$emit("startProgress")

			temp.idx = this.idx
			temp.emailId = this.getSessionProc().emailId;

			this.axiosCaller.get(this, this.APIs.MY_SERVICE + "/getMyInviteOne", temp, (res) => {
				var result = res.data
				if (result.status === "ok") {

					if (result.invite == null) {
						this.swalUtils.gritter("잘못된 접근입니다.", result.msg, "error").then(() => {
							//self.$router.push('/')
							history.back();
						});
					}

					self.invite = result.invite;
					console.log(':::;result.invite',result.invite)

				} else {
					this.swalUtils.gritter("Error", result.msg, "error")
				}
				this.$eventBus.$emit("doneProgress")
			})

		}

	}
}
</script>

<style scoped>

</style>